.container {
  padding: 2em 0em 2em 2em;
  overflow-y: scroll;
  box-sizing: content-box;
}

.graphComplianceContainer {
  display: flex;
  flex-direction: row;
  margin: 1em 2em 0 0;
}

/* ::-webkit-scrollbar { 
  display: none; 
} */

.chartContainer {
  width: 100%;
  height: 85%;
}

.topCard {
  margin-right: 2em;
  background: white;
  min-height: 140px;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.graphContainer {
  background: white;
  width: 70%;
  min-height: 500px;
  height: 69vh;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.graphHeader {
  padding: 1em 0 0 1em;
}

.graphHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.select {
  padding: 1em 2em 0 1em;
}

.topHeader {
  font-weight: bold;
  font-size: 40px;
  line-height: 1.2;
  margin: 0;
}

.headerDescription {
  text-align: center;
  font-weight: bold;
}

.complianceContainer {
  background-color: white;
  margin-left: 1em;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  min-height: 500px;
  height: 69vh;
}

.complianceHeader {
  font-size: 18px;
  font-family: MyriadPro;
  padding-top: 1em;
  text-transform: uppercase;
}

.complianceLabel {
  font-size: 14px;
  line-height: 17px;
  font-family: MyriadPro;
  padding-top: 2em;
}

.complianceSelect {
  width: 75%;
  padding-bottom: 1em;
}

.averageComplianceContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 200px;
}

.averageComplianceContainer h3 {
  font-size: 40px;
  font-family: MyriadPro;
  margin: 0;
}

.averageComplianceContainer h4 {
  font-size: 16px;
  font-family: MyriadPro;
}

.disclaimerContainer {
  position: fixed;
  bottom: 0;
  margin: 1em 2em 0 0;
  height: 2em;
}

.disclaimer {
  position: unset;
  margin: 2em 2em 0em 2em;
}
