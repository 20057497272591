.form {
  display: flex;
  flex-direction: column;
}

.HomeContainer {
  text-align: center;
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
}

.eyePassword {
  outline: none;
  border: none;
  padding: 0;
}

.input {
  /* padding: 1em 0 1em 0em; */
  margin: 0 auto;
  width: 25%;
  max-width: 400px;
  color: var(--light-gray);
}

.loginButton {
  width: 25%;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 1em;
  text-transform: uppercase;
  font-weight: bold;
}

.messageLogIn {
  padding-top: 1em;
}

.homeIcon {
  color: var(--light-gray);
}

.logo {
  margin-bottom: 4em;
}

.ant-row.ant-form-item {
  margin-bottom: 40;
}

.disclaimer {
  padding: 0 2em;
}
