.container {
  padding: 2em 0em 0em 2em;
  background-color: #f0f2f5;
  overflow-y: scroll;
}

.content {
  padding-right: 2em;
}

.contentBox {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  height: 85vh;
  min-height: 650px;
}

.contactContainer {
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

.formContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2em 0em 0em 0em;
}

.innerContainer {
  display: flex;
  justify-content: space-between;
}

.contactInformation {
  flex-basis: 75%;
  padding-left: 2em;
}

.innerContainer > h4 {
  color: #606060;
}

.addressContainer > h4 {
  color: #606060;
}

.fieldItem {
  flex-basis: 50%;
  margin-bottom: 0;
}

.fieldItem:first-of-type {
  padding-right: 1em;
}

.helpHeader {
  margin: 0 0 1em 0;
}

.subject {
  margin: 0;
}

.telephone {
  padding-top: 3em;
}

.submitButton {
  text-transform: uppercase;
  min-width: 300px;
  display: block;
  font-weight: bold;
}

.textArea {
  min-height: 200px;
}

.disclaimerContainer {
  position: fixed;
  bottom: 0;
  margin: 1em 2em 0 0;
  height: 2em;
}

.disclaimer {
  position: unset;
  margin: 2em 2em 0em 2em;
}
