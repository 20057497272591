.disclaimer {
  position: unset;
  margin: 2em 2em 0em 2em;
}

.container {
  padding: 2em 0em 0em 2em;
  background-color: #f0f2f5;
  overflow-y: scroll;
}

.content {
  padding: 2em;
  height: 85vh;
  background-color: white;
  min-height: 650px;
}
