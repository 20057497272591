.container {
  padding: 2em 0em 2em 2em;
  overflow-y: scroll;
}

.tableContainer {
  background: white;
  margin-right: 2em;
  padding: 2em 2em 0em 2em;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  height: 85vh;
  min-height: 650px;
}

.topHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.table {
  width: auto;
  height: auto;
}

.downloadButton {
  width: 25%;
  text-transform: uppercase;
  font-weight: bold;
  min-width: 160px;
}

.disclaimerContainer {
  position: fixed;
  bottom: 0;
  margin: 1em 2em 0 0;
  height: 2em;
}

.disclaimer {
  position: unset;
  margin: 2em 2em 0em 2em;
}
