@font-face {
  font-family: MyriadPro;
  src: url("./assets/MyriadPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: MyriadProBold;
  src: url("./assets/MyriadPro-Bold.otf") format("opentype");
}

@font-face {
  font-family: MyriadProLight;
  src: url("./assets/MyriadPro-Light.otf") format("opentype");
}

thead div,
td {
  font-family: MyriadPro;
}

h1,
h2,
h3,
h4 {
  font-family: MyriadProBold;
}

body {
  margin: 0;
  font-family: MyriadPro;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  background-color: #f0f2f5;
}

:root {
  --light-gray: #cfcfcf;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: white !important;
}

ul.ant-pagination.ant-table-pagination {
  margin: 1em 0 .5em 0;
  text-align: center;
  float: none;
}