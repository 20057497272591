.container {
  padding: 2em 0em 0em 2em;
  overflow-y: scroll;
}

.adminTable {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  margin-right: 2em;
}

.giveAccessContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-top: 2em;
  margin-right: 2em;
}

.giveAccessContainer button {
  text-transform: uppercase;
  font-size: 14px;
  font-family: MyriadProBold;
  padding: 0 2em 0 2em;
  width: 25%;
  min-width: 160px;
}

.disclaimerContainer {
  position: fixed;
  bottom: 0;
  margin: 1em 2em 0 2em;
  height: 2em;
}

.disclaimer {
  position: unset;
  margin: 2em 2em 0em 2em;
}
