.table {
  width: 50%;
}

td,
thead {
  padding: 1em 0em 1em 0em;
}

.tableContainer {
  display: flex;
}

.heartRate {
  color: #0088eb;
  font-weight: bold;
}

.respRate {
  color: #ac3fee;
  font-weight: bold;
}

.sp2 {
  color: #00ba8d;
  font-weight: bold;
}

.disclaimer {
  position: unset;
  margin: 0 0 1em 0;
  font-size: 0.95em;
  padding-right: 2em;
}
