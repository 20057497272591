.disclaimer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 2em;
  background-color: transparent;
  font-family: MyriadPro;
  color: #0088eb;
  font-size: 1em;
  line-height: 1.2em;
}
