.container {
  display: flex;
  padding-right: 2em;
}

.information {
  display: flex;
  flex-direction: column;
  padding-left: 0.5em;
  padding-bottom: 0.5em;
}

.icon {
  align-self: center;
  color: #5c5c5c;
}

.email {
  color: #5c5c5c;
  font-family: MyriadProBold;
}

.user {
  color: #5c5c5c;
  opacity: 0.5;
}
